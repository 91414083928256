import {graphql} from "gatsby"
import React, { Component } from "react"
import PropTypes from "prop-types"
import LandingPageLayout from "../components/landingPageLayout"

class Home extends Component {
  render() {
    return (
      <LandingPageLayout postData={this.props.data.wpPage}/>
    )
  }
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Home

export const pageQuery = graphql`
  query {
    wpPage(isFrontPage: {eq: true}) {
      id
      title
      landingPage {
        landingHeaderType
        landingHeaderText {
          title
          headline
        }
        landingHeaderCtaHero {
          headline
          template
          text
          title
          cta1 {
            link
            label
          }
          cta2 {
            link
            label
          }
          image {
            sourceUrl
            altText
          }
        }
        block {
          ... on WpPage_Landingpage_Block_Pricing {
            fieldGroupName
            template
            pricingPlans {
              cta {
                url
                title
                target
              }
              headline
              priceMonthly
              priceYearly
              title
              features {
                featuresItem
              }
              color
            }
          }
          ... on WpPage_Landingpage_Block_Faq {
            headline
            faq {
              answer
              question
            }
            template
          }
          ... on WpPage_Landingpage_Block_Logos {
            fieldGroupName
            headline
            clientLogos {
              logoImage {
                altText
                srcSet
                sourceUrl
              }
              logoLink {
                url
                target
                title
              }
            }
            template
          }
          ... on WpPage_Landingpage_Block_Testimonials {
            template
            fieldGroupName
            headline
            testimonials {
              avatar {
                altText
                srcSet
                sourceUrl
              }
              jobTitle
              name
              text
            }
          }
          ... on WpPage_Landingpage_Block_CtaImage {
            template
            headline
            text
            title
            image {
              sourceUrl
              altText
            }
            cta1 {
              link
              label
            }
            cta2 {
              link
              label
            }
          }
          ... on WpPage_Landingpage_Block_Teaser {
            template
            text
            title
          }
          ... on WpPage_Landingpage_Block_Cards {
            template
            title
            cards {
              headline
              image {
                sourceUrl
                altText
              }
              text
            }
          }
          ... on WpPage_Landingpage_Block_CtaFullStack {
            template
            title
            text
            label
            link
          }
          ... on WpPage_Landingpage_Block_CtaStackBundle {
            template
            title
            cta {
              url
              title
              target
            }
          }
          ... on WpPage_Landingpage_Block_Mailchimp {
            fieldGroupName
            template
            title
            text
            consent
          }
          ... on WpPage_Landingpage_Block_Hubspot {
            fieldGroupName
            template
            title
            text
            consent
          }
          ... on WpPage_Landingpage_Block_Stackcards {
            fieldGroupName
            template
            title
            stackscards {
              ... on WpTraining {
                databaseId
                nodeType: contentTypeName
                title
                slug
                uri
                acf {
                  tenjinArticleNumber
                  tenjinHeadline
                  tenjinRelatedContent {
                    ... on WpTraining {
                      databaseId
                      nodeType
                      title
                      slug
                      uri
                      acf {
                        tenjinCardExcerpt
                        tenjinArticleNumber
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                        tenjinPaidContent
                        tenjinRelatedContent {
                          ... on WpAnalyse {
                            databaseId
                            id
                            slug
                            title
                            nodeType
                            uri
                            acf {
                              iconColor {
                                mediaDetails {
                                  height
                                  width
                                }
                                sourceUrl
                              }
                              tenjinUpcomingContent
                              tenjinNoContent
                              tenjinNewContent
                            }
                          }
                          ... on WpDestination {
                            databaseId
                            id
                            slug
                            title
                            nodeType
                            uri
                            acf {
                              iconColor {
                                mediaDetails {
                                  height
                                  width
                                }
                                sourceUrl
                              }
                              tenjinUpcomingContent
                              tenjinNoContent
                              tenjinNewContent
                            }
                          }
                          ... on WpSource {
                            databaseId
                            id
                            slug
                            title
                            nodeType
                            uri
                            acf {
                              iconColor {
                                mediaDetails {
                                  height
                                  width
                                }
                                sourceUrl
                              }
                              tenjinUpcomingContent
                              tenjinNoContent
                              tenjinNewContent
                            }
                          }
                        }
                      }
                    }
                    ... on WpAnalyse {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpDestination {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpSource {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                  }
                  tenjinCardExcerpt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                  tenjinPaidContent
                }
                
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                 nodeType: contentTypeName
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
                categories: analyseCategories {
                  nodes {
                    slug
                    name
                  }
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType: contentTypeName
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
                categories: destinationCategories {
                  nodes {
                    slug
                    name
                  }
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType: contentTypeName
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  iconAlt
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
                categories: sourceCategories {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          } 
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
      }
    }
  }
`
